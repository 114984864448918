import { defineStore } from "pinia";
import { ref } from "vue";
import { scrollLock } from "@magnit/core/src/helpers/scrollLock";

export enum MODALS {
  CatalogFilters = "catalog-filters",
  CatalogFiltersGridSearch = "catalog-filters-grid-search",
  CityPrompt = "city-prompt",
  Cities = "cities",
  Stores = "stores",
  Login = "login",
  Logout = "logout",
  CardsInfo = "cards-info",
  ProfileDelete = "profile-delete",
  ProductShare = "product-share",
  EmailConfirm = "email-confirm",
  Calendar = "calendar",
  CardGuide = "card-guide",
  CardMerge = "card-merge",
  CardMergeDelay = "card-merge-delay",
  FavoriteStoresAlert = "favorite-stores-alert",
}

export const useModalsStore = defineStore("modals", () => {
  const openedModals = ref<string[]>([]);

  const getActive = (): string | null =>
    openedModals.value.length > 0 ? openedModals.value[0] : null;

  const isOpen = (modal: MODALS | string): boolean =>
    openedModals.value.includes(modal);

  const open = (name: string, clearable?: boolean, manual?: boolean) => {
    if (clearable) {
      openedModals.value = [name];
    } else {
      openedModals.value.unshift(name);
    }
    if (!manual) {
      scrollLock.enable();
    }
  };

  const close = (name: string) => {
    openedModals.value = openedModals.value.filter((n) => n !== name);

    if (!openedModals.value.length) {
      scrollLock.disable();
    }
  };

  const closeAll = () => {
    openedModals.value = [];
    scrollLock.disable();
  };

  return {
    getActive,
    open,
    close,
    closeAll,
    isOpen,
  };
});
